/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { ContentfulRichText } from "../components"

export const ImageContent = ({ fluid, richText, ...props }) => {
  return (
    <div
      sx={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: ["1fr", "1fr", "1fr 2fr"],
        gridGap: [3, 4, 5],
      }}
      {...props}
    >
      {fluid && (
        <Image
          sx={{
            width: [200, 400],
            height: [200, 400],
            borderRadius: "50%",
            border: theme => `6px solid ${theme.colors.gray3}`,
            mx: "auto",
          }}
          fluid={fluid}
        />
      )}
      {richText && (
        <ContentfulRichText
          sx={{
            "& h2": { fontSize: 5 },
            "& h3": { fontSize: 4 },
          }}
          content={richText}
        />
      )}
    </div>
  )
}

ImageContent.defaultProps = {
  fluid: null,
  richText: null,
}

ImageContent.propTypes = {
  fluid: PropTypes.object,
  richText: PropTypes.object,
}

export default ImageContent
