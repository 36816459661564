/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"
import { ContentfulRichText } from "../components"
import { Plus } from "@styled-icons/boxicons-regular/Plus"
import { Minus } from "@styled-icons/boxicons-regular/Minus"

export const FAQList = ({ list }) => {
  const [id, setID] = useState(list[0].node.id)
  return (
    <div>
      {list.map(({ node }) => (
        <div key={node.id}>
          <Heading
            onClick={() => setID(node.id)}
            className={id === node.id ? "active" : ""}
            as="h3"
            py={3}
            sx={{
              cursor: "pointer",
              display: "flex",
              transition: "color 0.3s",
              "&:hover, &.active": {
                color: "secondary",
              },
            }}
          >
            {id === node.id ? (
              <Minus sx={{ flex: "none", mr: "12px" }} size="20" />
            ) : (
              <Plus sx={{ flex: "none", mr: "12px" }} size="20" />
            )}
            {node.title}
          </Heading>
          {id === node.id && (
            <ContentfulRichText
              sx={{ pl: 4, color: "gray4" }}
              content={node.description}
            />
          )}
        </div>
      ))}
    </div>
  )
}

FAQList.defaultProps = {
  list: [],
}

FAQList.propTypes = {
  list: PropTypes.array.isRequired,
}

export default FAQList
