/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import PropTypes from "prop-types"
import Image from "gatsby-image"

export const TitleGridList = ({ title, list, ...props }) => {
  return (
    <div {...props}>
      <Heading
        as="h2"
        sx={{
          fontSize: [5, 6],
          textAlign: "center",
          mb: [4, 5],
        }}
      >
        {title}
      </Heading>
      <ul
        sx={{
          listStyle: "none",
          m: 0,
          p: 0,
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gridGap: [3, 5],
        }}
      >
        {list.map(({ title, description, icon }) => (
          <li key={title} sx={{ textAlign: "center" }}>
            <Image
              sx={{
                height: 200,
                width: 200,
                borderRadius: "50%",
                mx: "auto",
                border: theme => `6px solid ${theme.colors.gray3}`,
                mb: 4,
              }}
              fluid={icon.fluid}
            />
            <div>
              <Heading>{title}</Heading>
              <p sx={{ maxWidth: 360, mx: "auto", color: "gray4" }}>
                {description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

TitleGridList.defaultProps = {
  list: [],
  title: "",
}

TitleGridList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
}

export default TitleGridList
