/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"

export default ({ ...props }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          nav {
            menu
            slug
          }
        }
      }
    }
  `)

  const { nav } = data.site.siteMetadata

  return (
    <nav sx={{ bg: "text" }} {...props}>
      <Container px={3} py={4}>
        {nav && (
          <ul
            sx={{
              m: 0,
              p: 0,
              listStyle: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {nav.map(({ menu, slug }) => (
              <li key={menu}>
                <Link
                  to={slug}
                  activeClassName="active"
                  sx={{
                    color: "gray1",
                    textDecoration: "none",
                    px: 3,
                    fontSize: 3,
                    textTransform: "uppercase",
                    transition: "all 0.3s",
                    "&:hover, &.active": {
                      color: "background",
                    },
                  }}
                >
                  {menu}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </Container>
    </nav>
  )
}
