/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import PropTypes from "prop-types"
import Image from "gatsby-image"

export const BannerContent = ({ title, description, fluid, ...props }) => {
  return (
    <div sx={{ position: "relative" }} {...props}>
      {fluid && <Image sx={{ height: ["500px", "100%"] }} fluid={fluid} />}
      <div
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          color: "background",
          width: "100%",
          height: "auto",
          px: 3,
          py: 4,
          textAlign: "center",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          {title}
        </Heading>
        <div sx={{ fontSize: [3, 4] }}>{description}</div>
      </div>
    </div>
  )
}

BannerContent.defaultProps = {
  title: "",
  description: "",
  fluid: null,
}

BannerContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  fluid: PropTypes.object,
}

export default BannerContent
