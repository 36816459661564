/** @jsx jsx */
import { jsx } from "theme-ui"
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Bold = ({ children }) => <span tw="font-bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => null,
  },
}

const RichText = ({ content, ...props }) => { 
  return (
    <div {...props}>{renderRichText(content, options)}</div>
  )
}

export default RichText