/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { Link, useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo" }) {
        id
        publicURL
      }
    }
  `)

  return (
    <header sx={{ bg: "background", py: [0, 2] }}>
      <Container
        px={3}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/"
          sx={{
            width: 150,
            display: "inline-block",
            textDecoration: "none",
          }}
        >
          <img
            sx={{ width: "100%", verticalAlign: "middle" }}
            src={data.file.publicURL}
            alt="IBuyDFW"
          />
        </Link>
        <div sx={{ textAlign: "right" }}>
          <div>
            <div
              sx={{
                fontWeight: "heading",
                fontSize: 4,
                lineHeigh: 1,
                display: ["none", "block"],
              }}
            >
              CALL US NOW
            </div>
            <div
              sx={{
                lineHeight: 1,
              }}
            >
              <div
                sx={{
                  display: ["none", "block"],
                  color: "secondary",
                  fontSize: 4,
                  fontWeight: "heading",
                  textDecoration: "none",
                }}
              >
                972-IBUYDFW (428-9339)
              </div>
              <a
                href="tel:+972-428-9339"
                sx={{
                  color: "secondary",
                  display: "inline-block",
                  fontSize: 4,
                  fontWeight: "heading",
                  textDecoration: "none",
                }}
              >
                <svg
                  sx={{ fill: "secondary", display: [null, "none"] }}
                  width="32"
                  role="img"
                  viewBox="0 0 512 512"
                >
                  <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </header>
  )
}
