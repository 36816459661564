/** @jsx jsx */
import React, { useState } from "react"
import { jsx, Container } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"

const iconCSS = {
  fill: "background",
  width: "24px",
  position: "absolute",
  left: "50%",
  top: "0",
  transition: "all 0.3s",
}

export default ({ ...props }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          nav {
            menu
            slug
          }
        }
      }
    }
  `)

  const { nav } = data.site.siteMetadata
  const [active, setActive] = useState(false)

  return (
    <React.Fragment>
      <nav
        className={active ? "active" : ""}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          bg: "text",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 888,
          opacity: 0,
          visibility: "hidden",
          transition: "all 0.3s",
          "&.active": {
            opacity: 1,
            visibility: "visible",
          },
        }}
        {...props}
      >
        <Container px={3} sx={{ flex: "1 1 auto", maxWidth: 280 }}>
          <ul sx={{ p: 0, m: 0, listStyle: "none" }}>
            {nav.map(({ menu, slug }) => (
              <li key={menu}>
                <Link
                  to={slug}
                  activeClassName="active"
                  sx={{
                    color: "gray1",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    fontSize: 4,
                    py: 2,
                    display: "inline-block",
                    "&.active": {
                      color: "background",
                    },
                  }}
                >
                  {menu}
                </Link>
              </li>
            ))}
          </ul>
        </Container>
      </nav>
      <button
        className={active ? "active" : ""}
        onClick={() => setActive(!active)}
        sx={{
          border: 0,
          width: "56px",
          height: "56px",
          borderRadius: "50%",
          bg: "text",
          position: "fixed",
          zIndex: 999,
          bottom: theme => `${theme.space[4]}px`,
          right: theme => `${theme.space[4]}px`,
          outline: "none",
          transition: "all 0.3s",
          p: 0,
          m: 0,
          "&.active": {
            bg: "background",
          },
        }}
      >
        <svg
          role="img"
          viewBox="0 0 448 512"
          className={active ? "active" : ""}
          sx={{
            ...iconCSS,
            transform: "translateX(-50%) translateY(6px)",
            "&.active": {
              fill: "text",
              transform: "translateX(-50%) translateY(19px)",
            },
          }}
        >
          <path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path>
        </svg>
        <svg
          role="img"
          viewBox="0 0 448 512"
          className={active ? "active" : ""}
          sx={{
            ...iconCSS,
            transform: "translateX(-50%) translateY(23px)",
            "&.active": {
              fill: "text",
              transform: "translateX(-50%) translateY(10px)",
            },
          }}
        >
          <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
        </svg>
      </button>
    </React.Fragment>
  )
}
