/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { Header, Footer, DesktopNav, MobileNav } from "../components"

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <DesktopNav sx={{ display: ["none", null, "block"] }} />
      <div sx={{ display: [null, null, "none"] }}>
        <MobileNav />
      </div>
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
