/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { ContentfulRichText } from "../components"

export const QuoteBox = ({ richText, ...props }) => {
  return (
    <div
      sx={{
        bg: "primary",
        px: [4, 5],
        py: [3, 4],
        color: "background",
        maxWidth: 832,
        mx: "auto",
        borderLeft: theme => `6px solid ${theme.colors.gray3}`,
      }}
      {...props}
    >
      {richText && (
        <ContentfulRichText sx={{ fontSize: 3 }} content={richText} />
      )}
    </div>
  )
}

QuoteBox.defaultProps = {
  richText: null,
}

QuoteBox.propTypes = {
  richText: PropTypes.object,
}

export default QuoteBox
