/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import { PhoneAlt } from "@styled-icons/fa-solid/PhoneAlt"
import { Envelope } from "@styled-icons/fa-solid/Envelope"
import { MapMarkerAlt } from "@styled-icons/fa-solid/MapMarkerAlt"
import { Twitter } from "@styled-icons/fa-brands/Twitter"
import { FacebookF } from "@styled-icons/fa-brands/FacebookF"
import { Pinterest } from "@styled-icons/fa-brands/Pinterest"
import { LinkedinIn } from "@styled-icons/fa-brands/LinkedinIn"

const contactLabelCSS = {
  color: "background",
  textDecoration: "none",
  fontSize: 3,
  mt: 2,
  display: "inline-block",
}

const socialIconsCSS = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 48,
  height: 48,
  bg: "background",
  borderRadius: "50%",
  mx: 2,
}

export default () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            phone
            email
            address
            twitter
            facebook
            pinterest
            linkedin
          }
        }
      }
    }
  `)

  const { contacts } = data.site.siteMetadata

  return (
    <footer sx={{ bg: "text", color: "background" }}>
      <Container px={3} py={[4, 5, 100]}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
            gridGap: 4,
            pb: [4, 5],
          }}
        >
          <div sx={{ textAlign: "center" }}>
            <a href={`tel:${contacts.phone}`}>
              <PhoneAlt size="32" sx={{ color: "background" }} />
            </a>
            <br />
            <a href={`tel:${contacts.phone}`} sx={{ ...contactLabelCSS }}>
              {contacts.phone}
            </a>
          </div>
          <div sx={{ textAlign: "center" }}>
            <a href={`mailto:${contacts.email}`}>
              <Envelope size="32" sx={{ color: "background" }} />
            </a>
            <br />
            <a href={`mailto:${contacts.email}`} sx={{ ...contactLabelCSS }}>
              {contacts.email}
            </a>
          </div>
          <div sx={{ textAlign: "center" }}>
            <a
              href="https://goo.gl/maps/Unm135wNFiooHj2AA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MapMarkerAlt size="32" sx={{ color: "background" }} />
            </a>
            <br />
            <a
              href="https://goo.gl/maps/Unm135wNFiooHj2AA"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ ...contactLabelCSS }}
            >
              <div dangerouslySetInnerHTML={{ __html: contacts.address }} />
            </a>
          </div>
        </div>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: 4,
          }}
        >
          <a
            href={contacts.twitter}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ ...socialIconsCSS }}
          >
            <Twitter size="20" sx={{ color: "text" }} />
          </a>
          <a
            href={contacts.facebook}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ ...socialIconsCSS }}
          >
            <FacebookF size="20" sx={{ color: "text" }} />
          </a>
          <a
            href={contacts.pinterest}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ ...socialIconsCSS }}
          >
            <Pinterest size="22" sx={{ color: "text" }} />
          </a>
          <a
            href={contacts.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ ...socialIconsCSS }}
          >
            <LinkedinIn size="20" sx={{ color: "text" }} />
          </a>
        </div>
        <div sx={{ textAlign: "center" }}>
          Copyright &copy; {new Date().getFullYear()}{" "}
          <Link sx={{ color: "gray1", textDecoration: "none" }} to="/">
            www.IBuyDFW.com
          </Link>
          , All Rights Reserved{" "}
          <Link
            sx={{ color: "gray1", textDecoration: "none" }}
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>{" "}
          /{" "}
          <Link sx={{ color: "gray1", textDecoration: "none" }} to="/investors">
            Investors
          </Link>
        </div>
      </Container>
    </footer>
  )
}
